import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-material-ui';
import * as Yup from 'yup';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Button, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { placeService } from '../../services/api/placeService';
import { notify, ERROR } from '../../services/notification';
import { categoryService } from '../../services/api/categoryService';
import { attributeService } from '../../services/api/attributeService';
import { attributesGroupService } from '../../services/api/attributesGroupService';

const PlaceForm = (props) => {
  const formikRef = useRef();
  const [categories, setCategories] = useState(null);
  const [attributes, setAttributes] = useState(null);
  const [attributesGroups, setAttributesGroups] = useState(null);

  useEffect(() => {
    categoryService
      .getAll()
      .then((res) => {
        setCategories(res.data);
      })
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Fetching list of categories failed.' }),
        );
      });

    attributeService
      .getAll()
      .then((res) => {
        setAttributes(res.data);
      })
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Fetching list of attributes failed.' }),
        );
      });

    attributesGroupService
      .getAll()
      .then((res) => {
        setAttributesGroups(res.data);
        console.log(res.data);
      })
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Fetching list of attributes groups failed.' }),
        );
      });

    if (!props.isAddMode && props.id !== null) {
      placeService
        .getById(props.id)
        .then((res) => {
          Object.keys(initialValues).forEach((field) => {
            if (field === 'categories' || field === 'attributes') {
              const selected = res.data[field].map((collection) => collection.id);

              formikRef.current.setFieldValue(field, selected, false);
            } else {
              formikRef.current.setFieldValue(field, res.data[field], false);
            }
          });
        })
        .catch(() => {
          notify(
            ERROR,
            props.intl.formatMessage({ id: 'Opss...' }),
            props.intl.formatMessage({ id: 'Fetching place failed.' }),
          );
        });
    }
  }, []);

  let initialValues = {
    name: '',
    description: '',
    note: '',
    latitude: '',
    longitude: '',
    categories: [],
    attributes: [],
    sources: [],
    isPublished: false,
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required(props.intl.formatMessage({ id: 'Field is required' }))
      .max(255),
    description: Yup.string()
      .nullable()
      .max(10000),
    note: Yup.string()
      .nullable()
      .max(10000),
    latitude: Yup.string()
      .required(props.intl.formatMessage({ id: 'Field is required' }))
      .max(255)
      .matches(
        /^(-?\d+(\.\d+)?)$/,
        props.intl.formatMessage({ id: 'Invalid coordinate format' }),
      )
      .test(
        'checkLatitude',
        props.intl.formatMessage({ id: 'Latitude has to be in range -90 to 90' }),
        (value) => Number(value) >= -90 && Number(value) <= 90,
      ),
    longitude: Yup.string()
      .required(props.intl.formatMessage({ id: 'Field is required' }))
      .max(255)
      .matches(
        /^(-?\d+(\.\d+)?)$/,
        props.intl.formatMessage({ id: 'Invalid coordinate format' }),
      )
      .test(
        'checkLongitude',
        props.intl.formatMessage({ id: 'Longitude has to be in range -180 to 180' }),
        (value) => Number(value) >= -180 && Number(value) <= 180,
      ),
    categories: Yup.array()
      .required(props.intl.formatMessage({ id: 'Field is required' })),
    attributes: Yup.array(),
    sources: Yup.array(),
    isPublished: Yup.bool(),
  });

  const onSubmit = (values, actions) => {
    if (props.isAddMode) {
      props.create(values, actions);
    } else {
      props.update(props.id, values, actions);
    }
  };

  const splitCoordinates = (value, delimiter) => {
    let split = value.split(delimiter);

    if (split.length === 2) {
      return split;
    }

    return null;
  };

  const addSource = (formikProps) => {
    const sources = [...formikProps.values.sources];

    sources.push({ link: '', isPublic: false });

    formikProps.setFieldValue('sources', sources);
  };

  const deleteSource = (formikProps, i) => {
    const sources = [...formikProps.values.sources];

    sources.splice(i, 1);

    formikProps.setFieldValue('sources', sources);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur
      validateOnChange
      render={(formikProps, isSubmitting) => (
        <Form
          method="POST"
          action="#"
        >
          <Grid
            container
            justify="center"
            spacing={3}
          >
            <Grid item md={8}>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  name="name"
                  margin="normal"
                  required
                  fullWidth
                  label={props.intl.formatMessage({ id: 'Name' })}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  name="description"
                  margin="normal"
                  fullWidth
                  multiline
                  rowsMax={Infinity}
                  label={props.intl.formatMessage({ id: 'Description' })}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  name="note"
                  margin="normal"
                  fullWidth
                  multiline
                  rowsMax={Infinity}
                  label={props.intl.formatMessage({ id: 'Note (not visible in app)' })}
                />
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    name="latitude"
                    margin="normal"
                    required
                    fullWidth
                    label={props.intl.formatMessage({ id: 'Latitude' })}
                    onChange={e => {
                      let latitudeValue = e.target.value;
                      if (latitudeValue && !formikProps.values.longitude) {
                        let longitudeValue = '';
                        let newCoordinates = splitCoordinates(latitudeValue, ',');

                        if (newCoordinates !== null) {
                          [latitudeValue, longitudeValue] = newCoordinates;
                        }

                        formikProps.setFieldValue('longitude', longitudeValue.trim());
                      }

                      formikProps.setFieldValue('latitude', latitudeValue.trim());
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    name="longitude"
                    margin="normal"
                    required
                    fullWidth
                    label={props.intl.formatMessage({ id: 'Longitude' })}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormControl margin="normal" fullWidth>
                  <InputLabel shrink htmlFor="tags">
                    <FormattedMessage id="Categories" />
                  </InputLabel>
                  <Field
                    component={TextField}
                    type="text"
                    name="categories"
                    margin="normal"
                    fullWidth
                    select
                    SelectProps={{
                      multiple: true,
                    }}
                    validateOnBlur
                    validateOnChange
                  >
                    {categories && categories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl margin="normal" fullWidth>
                  <InputLabel shrink htmlFor="tags">
                    <FormattedMessage id="Attributes" />
                  </InputLabel>
                  <Field
                    component={TextField}
                    type="text"
                    name="attributes"
                    margin="normal"
                    fullWidth
                    select
                    SelectProps={{
                      multiple: true,
                    }}
                    validateOnBlur
                    validateOnChange
                  >
                    {attributesGroups && attributesGroups.map(attributesGroup => (
                      attributesGroup.attributes
                        .sort((a, b) => {
                          if (a.sorting < b.sorting) {
                            return -1;
                          }

                          if (a.sorting > b.sorting) {
                            return 1;
                          }

                          return 0;
                        })
                        .map(attribute => (
                        <MenuItem key={attribute.id} value={attribute.id}>
                          {`[${attributesGroup.name.toUpperCase()}] ${attribute.name}`}
                        </MenuItem>
                      ))
                    ))}
                    {attributes && attributes
                      .filter(attribute => attribute.attributesGroup === null)
                      .map((attribute) => (
                      <MenuItem key={attribute.id} value={attribute.id}>{attribute.name}</MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>
              <div>
                <FormattedMessage id="Sources" />
                <IconButton
                  aria-label="add source row"
                  onClick={() => addSource(formikProps)}
                >
                  <AddIcon />
                </IconButton>
              </div>
              {formikProps.values.sources
                .sort((a, b) => {
                  if (a.createdAt < b.createdAt) {
                    return -1;
                  }

                  if (a.createdAt > b.createdAt) {
                    return 1;
                  }

                  return 0;
                })
                .map((source, i) => {
                return (
                  <Grid item xs={12} key={i}>
                    <Grid container spacing={3}>
                      <Grid item xs={2} style={{ marginTop: '30px' }}>
                        <Field
                          component={CheckboxWithLabel}
                          name={`sources.${i}.isPublic`}
                          type="checkbox"
                          Label={{ label: props.intl.formatMessage({ id: 'Public' }) }}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <Field
                          component={TextField}
                          name={`sources.${i}.link`}
                          margin="normal"
                          label={props.intl.formatMessage({ id: 'Link' })}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={1} style={{marginTop: '25px'}}>
                        <IconButton
                          aria-label="delete source row"
                          color="secondary"
                          onClick={() => deleteSource(formikProps, i)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
              <Divider variant="middle" style={{ margin: '40px 0'}} />
              <Grid item xs={12}>
                <Field
                  component={CheckboxWithLabel}
                  name="isPublished"
                  type="checkbox"
                  Label={{ label: props.intl.formatMessage({ id: 'Published' }) }}
                />
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                disabled={isSubmitting}
              >
                {props.intl.formatMessage({ id: 'Save' })}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    />
  );
};

PlaceForm.propTypes = {
  id: PropTypes.string,
  isAddMode: PropTypes.bool.isRequired,
  create: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

PlaceForm.defaultProps = {
  id: null,
};

export default injectIntl(PlaceForm);
