import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { categoryService } from '../../services/api/categoryService';
import { attributeService } from '../../services/api/attributeService';
import { ERROR, notify } from '../../services/notification';
import PlacesMap from './Map/PlacesMap';
import FiltersPanel from './Map/FiltersPanel';

const FilterablePlacesMap = (props) => {
  const [isPublished, setIsPublished] = useState(null);
  const [categories, setCategories] = useState([]);
  const [activeCategories, setActiveCategories] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [activeFiltersWithPositiveCondition, setActiveFiltersPositive] = useState([]);
  const [activeFiltersWithNegativeCondition, setActiveFiltersNegative] = useState([]);

  useEffect(() => {
    categoryService
      .getAll()
      .then((res) => {
        setCategories(res.data);
      })
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Fetching list of categories failed.' }),
        );
      });

    attributeService
      .getAll()
      .then((res) => {
        setAttributes(res.data);
      })
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Fetching list of attributes failed.' }),
        );
      });
  }, []);

  const updateActiveFilters = (e, attribute) => {
    const value = e.target.value;

    if (null === value) {
      setActiveFiltersPositive(activeFiltersWithPositiveCondition.filter(
        activeFilterId => activeFilterId !== attribute.id)
      );

      setActiveFiltersNegative(activeFiltersWithNegativeCondition.filter(
        activeFilterId => activeFilterId !== attribute.id)
      );

      return;
    }

    if (true === value) {
      setActiveFiltersPositive(activeFiltersWithPositiveCondition.concat([attribute.id]));
      setActiveFiltersNegative(activeFiltersWithNegativeCondition.filter(activeFilterId => (
        activeFilterId !== attribute.id
      )));
    } else {
      setActiveFiltersPositive(activeFiltersWithPositiveCondition.filter(activeFilterId => (
        activeFilterId !== attribute.id
      )));
      setActiveFiltersNegative(activeFiltersWithNegativeCondition.concat([attribute.id]));
    }
  };

  const resetFilters = () => {
    setIsPublished(null);
    setActiveCategories([]);
    setActiveFiltersPositive([]);
    setActiveFiltersNegative([]);
  };

  const getFilteredPlaces = () => {
    let filteredPlaces = props.places;

    if (null !== isPublished) {
      filteredPlaces = filteredPlaces.filter(place => place.isPublished === isPublished);
    }

    if (activeCategories.length) {
      filteredPlaces = filteredPlaces.filter(place => (
        place.categories.some(category => activeCategories.includes(category.id))
      ));
    }

    if (activeFiltersWithPositiveCondition.length) {
      filteredPlaces = filteredPlaces.filter(place => (
        activeFiltersWithPositiveCondition.every(attributeId => place.attributesIds.includes(attributeId))
      ));
    }

    if (activeFiltersWithNegativeCondition.length) {
      filteredPlaces = filteredPlaces.filter(place => (
        activeFiltersWithNegativeCondition.every(attributeId => !place.attributesIds.includes(attributeId))
      ));
    }

    return filteredPlaces;
  };

  return (
    <>
      <FiltersPanel
        isPublished={isPublished}
        setIsPublished={setIsPublished}
        categories={categories}
        activeCategories={activeCategories}
        setActiveCategories={setActiveCategories}
        attributes={attributes}
        updateActiveFilters={updateActiveFilters}
        activeFiltersWithPositiveCondition={activeFiltersWithPositiveCondition}
        activeFiltersWithNegativeCondition={activeFiltersWithNegativeCondition}
        resetFilters={resetFilters}
      />
      <PlacesMap
        isMounting={props.isMounting}
        filteredPlaces={getFilteredPlaces()}
        pinColorsBase={props.pinColorsBase}
      />
    </>
  );
};

FilterablePlacesMap.propTypes = {
  places: PropTypes.array,
  isMounting: PropTypes.bool,
  pinColorsBase: PropTypes.string
};

export default FilterablePlacesMap;
